<template>
  <loading-dialog :loading="$apollo.loading">
    <v-card flat class="py-4">
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            v-model="import_key"
            readonly
            label="Chave de importação / IMPORT_KEY"
            type="number"
            outlined
            hint="Apenas leitura"
            class="px-4 pt-4 mr-12"
          />
        </v-col>
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" style="width:min-content;" v-on="on">
                <v-switch v-model="full_etl" :disabled="!editable" class="pt-4" label="FULL_ETL" value />
              </div>
            </template>
            <span>Forçar recriação dos sumários</span>
          </v-tooltip>
        </v-col>
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" style="width:min-content;" v-on="on">
                <v-switch v-model="regenerate_loyalty" :disabled="!editable" class="pt-4" label="REGENERATE_LOYALTY" value />
              </div>
            </template>
            <span>Forçar recriação dos clientes fidelidade</span>
          </v-tooltip>
        </v-col>
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" style="width:min-content;" v-on="on">
                <v-switch v-model="truncate_loyalty" :disabled="!editable" class="pt-4" label="TRUNCATE_LOYALTY" value />
              </div>
            </template>
            <span>Forçar remoção de clientes fidelidade</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <btn-card-actions class="pb-4" :editable.sync="editable" :saving="saving" @save="save" @cancel="cancelRestore" />
    </v-card>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT, MUTATION_EDIT_STAGE } from '@/modules/accounts/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    BtnCardActions: () => import('@/components/base/BtnCardActions')
  },
  data: () => ({
    accountId: '',
    editable: false,
    import_key: '',
    full_etl: false,
    regenerate_loyalty: false,
    truncate_loyalty: false,
    saving: false
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}
        this.import_key = adminAccount?.stage?.import_key
        this.full_etl = adminAccount?.stage?.full_etl
        this.regenerate_loyalty = adminAccount?.stage?.regenerate_loyalty
        this.truncate_loyalty = adminAccount?.stage?.truncate_loyalty
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async save() {
      const variables = {
        id: this.accountId,
        import_key: +this.import_key,
        full_etl: Boolean(this.full_etl),
        regenerate_loyalty: Boolean(this.regenerate_loyalty),
        truncate_loyalty: Boolean(this.truncate_loyalty)
      }

      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_STAGE,
          variables
        })

        this.cancelRestore()
        this.$snackbar({ message: 'Salvo com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao salvar', snackbarColor: '#F44336' })
      }
      this.saving = false
    },
    cancelRestore() {
      this.editable = false
      this.$apollo.queries.adminAccount.refetch()
    }
  }
}
</script>

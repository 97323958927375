var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loading-dialog',{attrs:{"loading":_vm.$apollo.loading}},[_c('v-card',{staticClass:"py-4",attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"px-4 pt-4 mr-12",attrs:{"readonly":"","label":"Chave de importação / IMPORT_KEY","type":"number","outlined":"","hint":"Apenas leitura"},model:{value:(_vm.import_key),callback:function ($$v) {_vm.import_key=$$v},expression:"import_key"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"min-content"}},'div',attrs,false),on),[_c('v-switch',{staticClass:"pt-4",attrs:{"disabled":!_vm.editable,"label":"FULL_ETL","value":""},model:{value:(_vm.full_etl),callback:function ($$v) {_vm.full_etl=$$v},expression:"full_etl"}})],1)]}}])},[_c('span',[_vm._v("Forçar recriação dos sumários")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"min-content"}},'div',attrs,false),on),[_c('v-switch',{staticClass:"pt-4",attrs:{"disabled":!_vm.editable,"label":"REGENERATE_LOYALTY","value":""},model:{value:(_vm.regenerate_loyalty),callback:function ($$v) {_vm.regenerate_loyalty=$$v},expression:"regenerate_loyalty"}})],1)]}}])},[_c('span',[_vm._v("Forçar recriação dos clientes fidelidade")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"min-content"}},'div',attrs,false),on),[_c('v-switch',{staticClass:"pt-4",attrs:{"disabled":!_vm.editable,"label":"TRUNCATE_LOYALTY","value":""},model:{value:(_vm.truncate_loyalty),callback:function ($$v) {_vm.truncate_loyalty=$$v},expression:"truncate_loyalty"}})],1)]}}])},[_c('span',[_vm._v("Forçar remoção de clientes fidelidade")])])],1)],1),_c('btn-card-actions',{staticClass:"pb-4",attrs:{"editable":_vm.editable,"saving":_vm.saving},on:{"update:editable":function($event){_vm.editable=$event},"save":_vm.save,"cancel":_vm.cancelRestore}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }